
import {computed, defineComponent, onMounted, onUnmounted} from "vue";
import { useStore } from "vuex";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import { getIllustrationsPath } from "@/core/helpers/assets";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    const changeLanguage = (lang: string) => {
      store.commit(Mutations.SET_CHANGE_LANGUAGE, lang);
    };

    return {
      getIllustrationsPath,
      currentLanguage,
      changeLanguage
    };
  },
});
